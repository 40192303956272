import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ConstructionIcon from '@mui/icons-material/Construction';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import FloatingDrawer from '../common/FloatingDrawer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Confirm from '../common/Confirm';
import Tooltip from '@mui/joy/Tooltip';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Grid from '@mui/joy/Grid';
import OASImport from './OasImport';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { SiOpenapiinitiative } from 'react-icons/si';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import Tab, { tabClasses } from '@mui/joy/Tab';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import KeyIcon from '@mui/icons-material/Key';
import UploadIcon from '@mui/icons-material/DriveFolderUpload';
import Checkbox from '@mui/material/Checkbox';
import FunctionsIcon from '@mui/icons-material/Functions';
import { DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy';
import { Buffer } from 'buffer';

const generateBasicValue = (username, password) => {
  const authString = `${username}:${password}`;
  const authValue = Buffer.from(authString).toString('base64');
  return authValue;
};

const defaultParameter = { type: 'string' };
const defaultFunction = { implementation: 'stub', method: 'get' };

const validity = {
  name: /^[a-zA-Z0-9_]*$/,
  description: true,
  implementation: /(stub|rest|client)/,
  url: ({ implementation }) =>
    implementation === 'rest' &&
    /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  result: ({ implementation }) => implementation === 'stub',
  parameters: {
    name: /^[a-zA-Z0-9_]*$/,
    description: true,
    type: /(string|number|key)/
  }
};

function isValid(check, parent, value) {
  if (!check) return { valid: true };
  let test = check instanceof Function ? check(parent) : check;
  if (test instanceof RegExp)
    return {
      valid: value?.length && value.match(test),
      reason: value?.length
        ? `must match ${test.toString()}`
        : 'must not be empty'
    };
  if (typeof test === 'boolean')
    return { valid: !test || !!value?.length, reason: 'must not be empty' };
  return { valid: true };
}

function validate(index, functions) {
  let errors = { parameters: [] };
  let validated = false;
  Object.entries(validity).forEach(([key, validator]) => {
    if (key === 'parameters') {
      Object.entries(validator).forEach(([key, validator]) => {
        functions[index]?.parameters?.forEach((parameter, i) => {
          let { valid, reason } = isValid(validator, parameter, parameter[key]);
          if (!valid) {
            (errors.parameters[i] = errors.parameters[i] || {})[key] = reason;
          }
        });
      });
    } else {
      let { valid, reason } = isValid(
        validator,
        functions[index],
        functions[index][key]
      );
      if (!valid) {
        errors[key] = reason;
      }
    }
  });
  if (!errors.name && functions.filter(f => f.name === functions[index].name).length >= 2) {
    errors.name = `Must be unique: ${functions[index].name}() already exists`;
  }
  validated = Object.keys(errors).length <= 1 && !errors.parameters.length;
  return { errors, validated };
}

const FormWrapper = ({ placeholder, helperText, label, error, children, sx }) => {
  return (
    <FormControl {...{ error: !!error }} sx={{ mt: 1, ...sx }}>
      {label && <FormLabel>{label}</FormLabel>}
      {children}
      {(helperText || error) && (
        <FormHelperText>{helperText || error}</FormHelperText>
      )}
    </FormControl>
  );
};

const CheckedInput = ({
  placeholder,
  helperText,
  label,
  error,
  sx,
  ...rest
}) => {
  !placeholder && label && (placeholder = label.replace(/^.*\s/, ''));
  console.log({ sx, label }, 'checkedInput');
  return (
    <FormWrapper
      helperText={error || helperText}
      {...{ placeholder, label, error, ...rest }}
      sx={sx}
    >
      <Input
        placeholder={!!error ? `${placeholder} - ${error}` : placeholder}
        error={!!error}
        {...rest}
      />
    </FormWrapper>
  );
};

const CheckedSelect = ({
  helperText,
  label,
  children,
  sx,
  ...rest
}) => {
  return (
    <FormWrapper {...{ label, helperText, sx, error: false }}>
      <Select {...{ ...rest }}>{children}</Select>
    </FormWrapper>
  );
};

const EditKey = ({ value, index, onChange: handleChange }) => {

  let [viewPassword, setViewPassword] = useState(false);
  return (
    <Stack key={index} direction='row' spacing={1} sx={{ m: 1 }} useFlexGap>
      <CheckedInput
        label={!index && 'Key Name'}
        placeholder='Name'
        value={value.name}
        onChange={(e) => handleChange(index, 'name', e.target.value)}
        sx={{ mt: 0 }}
      />
      <CheckedSelect
        label={!index && 'In'}
        value={value.in}
        onChange={(e, newValue) => handleChange(index, 'in', newValue)}
        sx={{ mt: 0 }}
      >
        <Option value='path'>path</Option>
        <Option value='query'>query</Option>
        <Option value='basic'>Basic Auth</Option>
        <Option value='bearer'>Bearer</Option>
        <Option value='header'>Custom Header</Option>
      </CheckedSelect>
      <Stack direction='column' spacing={1}>
        {value.in === 'header' &&
          <CheckedInput
            label={'Header'}
            placeholder='Header'
            value={value.header}
            type={'text'}
            onChange={(e) => handleChange(index, 'header', e.target.value)}
          />}
        {value.in !== 'basic' && <CheckedInput
          label={!index && 'Value'}
          placeholder='Value'
          value={value.value}
          type={viewPassword ? 'text' : 'password'}
          endDecorator={
            viewPassword ? (
              <VisibilityOffIcon onClick={() => setViewPassword(false)} />
            ) : (
              <VisibilityIcon onClick={() => setViewPassword(true)} />
            )
          }
          onChange={(e) => handleChange(index, 'value', e.target.value)}
          sx={{ flexGrow: 1, mt: 'auto' }}
        />}
        {value.in === 'basic' &&
          <>
            <CheckedInput
              label={'Username'}
              placeholder='Username'
              value={value.username}
              type={'text'}
              onChange={(e) => handleChange(index, 'username', e.target.value)}
            />
            <CheckedInput
              label={'Password'}
              placeholder='Password'
              value={value.password}
              type={viewPassword ? 'text' : 'password'}
              endDecorator={
                viewPassword ? (
                  <VisibilityOffIcon onClick={() => setViewPassword(false)} />
                ) : (
                  <VisibilityIcon onClick={() => setViewPassword(true)} />
                )
              }
              onChange={(e) => handleChange(index, 'password', e.target.value)}
            />
          </>
        }
      </Stack>
      <Button
        variant='outlined'
        color='danger'
        size='sm'
        startDecorator={<RemoveIcon />}
        onClick={() => handleChange(index, null, null)}
        sx={{ mt: 'auto' }}
      >
        Delete
      </Button>
    </Stack>
  );
};

const MenuFab = ({ options, children }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <SpeedDial
      ariaLabel='Add Function'
      sx={{ position: 'absolute', bottom: 16, right: 16, whiteSpace: 'nowrap' }}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      {options.map((option, index) => (
        <SpeedDialAction
          key={index}
          icon={option.icon()}
          tooltipTitle={option.name}
          tooltipOpen
          onClick={option.onClick}
        />
      ))}
    </SpeedDial>
  );
};


const EditPop = ({ open, func, setFunctions, index, setConsistent, setIndex, apiKeys, functions }) => {
  let [formErrors, setFormErrors] = useState({});
  let [confirmDelete, setConfirmDelete] = useState(false);


  const handleReValidate = (field = false) => {
    let { errors } = validate(index, functions);
    setFormErrors(field ? ({ ...formErrors, [field]: errors[field] }) : errors);
  };

  const handleFunctionParamChange = (name, value) => {
    let newFunctions = [...functions];
    newFunctions[index][name] = value;
    switch (name) {
      case 'name':
        if (/^[a-zA-Z0-9_]*$/.test(value)) {
          setFunctions(newFunctions);
        }
        break;
      case 'url':
        setFunctions(newFunctions);
        break;
      default:
        setFunctions(newFunctions);
        break;
    }
  };

  const handleRemoveFunction = () => {
    const newFunctions = [...functions];
    newFunctions.splice(index, 1);
    setFunctions(newFunctions);
    setConsistent(true);
    setFormErrors({});
    setIndex(null);
  };

  const handleDone = () => {
    let { errors, validated } = validate(index, functions);
    setFormErrors(errors);
    if (validated) {
      setIndex(null);
      setConsistent(true);
    }
  };

  const handleParameterChange = (
    parameterIndex,
    field,
    value
  ) => {
    const newFunctions = [...functions];
    newFunctions[index].parameters[parameterIndex][field] = value;
    setFunctions(newFunctions);
  };

  const handleParameterDescriptionChange = (
    parameterIndex,
    value
  ) => {
    const newFunctions = [...functions];
    newFunctions[index].parameters[parameterIndex].description = value;
    setFunctions(newFunctions);
  };

  const handleAddParameter = () => {
    const newFunctions = [...functions];
    !newFunctions[index].parameters && (newFunctions[index].parameters = []);
    newFunctions[index].parameters.push({ ...defaultParameter });
    setFunctions(newFunctions);
  };


  const handleRemoveParameter = (parameterIndex) => {
    const newFunctions = [...functions];
    newFunctions[index].parameters.splice(parameterIndex, 1);
    setFunctions(newFunctions);
  };




  return (
    <Modal open={open} onClose={handleDone}>
      <ModalDialog
        sx={{
          width: '80%',
          maxHeight: '80%'
        }}
      >
        <ModalClose />

        <DialogTitle>Edit function</DialogTitle>
        {open && <Grid container spacing={2}
          sx={{
            overflowY: 'auto',
            overflowX: 'clip'
          }}>
          <Grid xs={12}>
            <Typography level='body-md'>
              <b>{func.name}</b> (
              {!!func.parameters &&
                func.parameters?.map(
                  (param, ind) => `${ind !== 0 ? ', ' : ''}${param.name}`
                )}
              )
            </Typography>
          </Grid>

          <Grid xs={12}>
            <CheckedInput
              label='Function Name'
              value={func.name}
              onChange={(e) =>
                handleFunctionParamChange('name', e.target.value)
              }
              onBlur={() => handleReValidate('name')}
              helperText='Unique function name, no spaces, starts with a letter and contains only letters, numbers and underscores'
              error={formErrors.name}
            />
          </Grid>
          <Grid xs={12}>
            <CheckedInput
              label='Function Description'
              value={func.description}
              onBlur={() => handleReValidate('description')}
              onChange={(e) =>
                handleFunctionParamChange(
                  'description',
                  e.target.value
                )
              }
              error={formErrors.description}
              helperText='Describes to the LLM what this function does; why it should be called. Mirror any language in prompt'
            />
          </Grid>
          <Grid xs={12} display="flex">
            <List display="flex">
              {func.parameters?.map((param, paramIndex) => (
                <>
                  <ListItem key={paramIndex}
                    sx={{
                      alignItems: 'start',
                    }}>
                    <CheckedInput
                      label={!paramIndex && 'Parameter Name'}
                      placeholder='Name'
                      value={param.name}
                      onBlur={() => handleReValidate('parameters')}
                      onChange={(e) =>
                        handleParameterChange(
                          paramIndex,
                          'name',
                          e.target.value
                        )
                      }
                      error={formErrors?.parameters?.[paramIndex]?.name}
                    />
                    <CheckedSelect
                      label={!paramIndex && 'Type'}
                      placeholder='Type'
                      value={param.type}
                      onBlur={() => handleReValidate('parameters')}
                      onChange={(e, newValue) =>
                        handleParameterChange(
                          paramIndex,
                          'type',
                          newValue
                        )
                      }
                    >
                      <Option value='string'>String</Option>
                      <Option value='number'>Number</Option>
                      <Option value='key'>API Key</Option>
                    </CheckedSelect>
                    <CheckedInput
                      label={!paramIndex && 'Parameter Description'}
                      placeholder='Description'
                      value={param.description}
                      onBlur={() => handleReValidate('parameters')}
                      onChange={(e) =>
                        handleParameterDescriptionChange(
                          paramIndex,
                          e.target.value
                        )
                      }
                      error={
                        formErrors?.parameters?.[paramIndex]?.description
                      }
                      sx={{ flexGrow: 1 }}
                    />
                    <Button
                      variant='outlined'
                      color='danger'
                      size='sm'
                      startDecorator={<RemoveIcon />}
                      onClick={() =>
                        handleRemoveParameter(paramIndex)
                      }
                      sx={{ mt: 'auto', ml: 'auto', mr: 0 }}
                    >
                      Delete
                    </Button>
                  </ListItem>
                </>
              ))}

              <ListItem key='add-parameters-row'>
                <Button
                  variant='solid'
                  size='sm'
                  startDecorator={<AddIcon />}
                  aria-label='add parameter'
                  sx={{ mt: 1 }}
                  onClick={() => handleAddParameter()}
                >
                  Add Parameter{func.parameters?.length === 0 && 's'}
                </Button>
              </ListItem>
            </List>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <CheckedSelect
              label='Implementation'
              value={func.implementation}
              sx={{ mr: 'auto' }}
              onChange={(e, newValue) =>
                handleFunctionParamChange(
                  'implementation',
                  newValue
                )
              }
            >
              <Option value='stub'>Stub</Option>
              <Option value='rest'>Web API</Option>
              <Option value='client'>Client</Option>
            </CheckedSelect>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            {func.implementation === 'rest' && (
              <CheckedSelect
                defaultValue='post'
                label='method'
                value={func.method}
                sx={{ ml: 0, mr: 'auto' }}
                onChange={(e, newValue) =>
                  handleFunctionParamChange('method', newValue)
                }
              >
                <Option value='get'>GET</Option>
                <Option value='post'>POST</Option>
              </CheckedSelect>
            )}
          </Grid>
          {func.implementation === 'rest' && <Grid xs={12} sm={12} md={6}>
            <CheckedSelect
              label='API Key'
              value={func.key}
              onChange={(e, newValue) => handleFunctionParamChange('key', newValue)}
            >
              {apiKeys.map((key) => (
                <Option value={key.name}>{key.name}</Option>
              )
              )}

            </CheckedSelect>
          </Grid>}
          <Grid sm={12}>{
            func.implementation === 'rest' && (
              <CheckedInput
                label='URL'
                value={func.url}
                onBlur={() => handleReValidate('url')}
                onChange={(e) =>
                  handleFunctionParamChange('url', e.target.value)
                }
                error={formErrors.url}
                helperText={`URL to ${func.method?.toUpperCase()}, use "{parameter-name}" to expand input parameters`}
              />)}
            {func.implementation !== 'rest' && (
              <CheckedInput
                label='Stubbed Result'
                value={func.result}
                onBlur={() => handleReValidate('url')}
                onChange={(e) =>
                  handleFunctionParamChange(
                    'result',
                    e.target.value
                  )
                }
                error={formErrors.result}
                helperText='Static stub string that will be returned for all calls, use "{parameter-name}" to substitute input parameters'
              />
            )}
          </Grid>
          <Grid xs={12}>
            <Button
              variant='solid'
              startDecorator={<DoneIcon />}
              onClick={() => handleDone()}
              sx={{ m: 2 }}
            >
              Done
            </Button>
            <Button
              variant='outlined'
              color='danger'
              startDecorator={<RemoveIcon />}
              onClick={() => setConfirmDelete(true)}
              sx={{ m: 1, mr: 0 }}
            >
              Delete
            </Button>
            <Confirm
              onConfirm={() => handleRemoveFunction()}
              open={confirmDelete}
              setOpen={setConfirmDelete}
              onClose={() => setConfirmDelete(false)}
              startDecoration={<RemoveIcon color='danger' />}
              title='Confirm Delete'
            >
              Function definition will be permanently deleted and cannot
              be restored
            </Confirm>
          </Grid>
          <Grid xs={12}>
            <Divider orientation='horizontal' />
          </Grid>
        </Grid>}
      </ModalDialog>
    </Modal>
  );
};

const FunctionEditor = ({
  functions,
  setFunctions,
  apiKeys,
  setApiKeys,
  consistent,
  setConsistent
}) => {
  let [editIndex, setEditIndex] = useState(null);
  let [confirmDelete, setConfirmDelete] = useState(false);
  let [oasOpen, setOasOpen] = useState(false);
  let [oasFromFile, setOasFromFile] = useState(false);
  let [selectAll, setSelectAll] = useState(false);
  let [selectedFunctions, setSelectedFunctions] = useState([]);
  let [showTab, setShowTab] = useState(0);

  console.log({ functions, apiKeys }, 'functions');


  const handleFunctionSelectionChange = (index) => {
    const updatedSelection = selectedFunctions.includes(index)
      ? selectedFunctions.filter((i) => i !== index)
      : [...selectedFunctions, index];
    setSelectedFunctions(updatedSelection);
  };


  const handleEditFunction = (index) => {
    setConsistent(false);
    setEditIndex(index);
  };



  const handleAddFunction = () => {
    let newFunctions = [...functions, { ...defaultFunction }];
    setFunctions(newFunctions);
    handleEditFunction(newFunctions.length - 1);
  };



  const handleRemoveSelectedFunctions = () => {
    const newFunctions = functions.filter(
      (_, index) => !selectedFunctions.includes(index)
    );
    setFunctions(newFunctions);
    setSelectedFunctions([]);
    setSelectAll(false);
  };

  const handleKeyChange = (index, key, value) => {
    let newApiKeys = [...apiKeys];
    console.log({ index, key, value, newApiKeys }, 'key change');
    if (key !== null) {
      newApiKeys[index][key] = value;
    } else {
      newApiKeys.splice(index, 1);
    }
    if ((key === 'username' || key === 'password') && apiKeys[index].in === 'basic') {
      let { username, password } = newApiKeys[index];
      newApiKeys[index].value = generateBasicValue(username, password);
      console.log({ username, password, value: newApiKeys[index].value }, 'basic auth set');
    }
    setApiKeys(newApiKeys);
  };

  const handleAddKey = () => {
    let newApiKeys = [...apiKeys, {}];
    setApiKeys(newApiKeys);
    setShowTab(1);
  };


  return (
    <>
      <Sheet
        sx={{
          height: '88%',
          overflow: 'auto',
          mx: 'calc(-1 * var(--ModalDialog-padding))',
          px: 'var(--ModalDialog-padding)'
        }}
      >
        <Tabs orientation="horizontal"
          onChange={(event, newValue) => setShowTab(newValue)}
          value={showTab}>
          <TabList
            sx={{
              [`& .${tabClasses.root}`]: {
                '&[aria-selected="true"]': {
                  bgcolor: 'background.surface',
                  borderColor: 'divider',
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    height: 2,
                    bottom: -2,
                    left: 0,
                    right: 0,
                    bgcolor: 'background.surface',
                  },
                },
              },
            }}
          >
            <Tab>
              <ListItemDecorator>
                <FunctionsIcon />
              </ListItemDecorator>
              Functions
            </Tab>
            <Tab>
              <ListItemDecorator>
                <KeyIcon />
              </ListItemDecorator>
              API Keys
            </Tab>
          </TabList>
          <TabPanel value={0}>
            {functions?.length > 1 && <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title='Select All'>
                    <Checkbox
                      checked={selectAll}
                      disabled={editIndex !== null}
                      onChange={(e) => {
                        setSelectAll(e.target.checked);
                        setSelectedFunctions(
                          e.target.checked ? functions.map((_, i) => i) : []
                        );
                      }}
                      sx={{ p: '4px' }}
                    />
                  </Tooltip>
                </Box>
                <Button
                  variant='plain'
                  color='danger'
                  startDecorator={<RemoveIcon />}
                  onClick={() => setConfirmDelete(true)}
                  disabled={selectedFunctions.length === 0}
                >
                  Delete Selected
                </Button>
              </Box>
              <Confirm
                onConfirm={() => handleRemoveSelectedFunctions()}
                open={confirmDelete}
                setOpen={setConfirmDelete}
                onClose={() => setConfirmDelete(false)}
                startDecoration={<RemoveIcon color='danger' />}
                title='Confirm Delete'
              >
                {`Are you sure you want to delete ${selectedFunctions.length} selected functions?`}
              </Confirm>
            </>}

            <Grid container>
              {!functions?.length && (
                <Grid xs={12}>
                  <Typography>
                    No Functions for the LLM to call yet. To test some, click on the{' '}
                    <AddCircleIcon
                      color='primary'
                      fontSize='inherit'
                      sx={{ align: 'baseline' }}
                    />{' '}
                    button below to start defining!
                  </Typography>
                </Grid>
              )}
              {functions.map((func, index) => (

                <>
                  <Grid xs={12} sm={10} sx={{ alignContent: 'space-around' }}>
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        checked={selectedFunctions.includes(index)}
                        onChange={() => handleFunctionSelectionChange(index)}
                        sx={{ p: '4px', mr: 2 }}
                      />
                      <Tooltip
                        variant='plain'
                        color='neutral'
                        title={
                          <Card sx={{ whiteSpace: 'pre' }}>
                            <CardContent>
                              {func.parameters?.map((param) => (
                                <Typography level='body-md' key={param.name}>
                                  {param.name}: <i>{param.type}</i> -{' '}
                                  {param.description}
                                </Typography>
                              ))}
                              <Divider />
                              {func.implementation === 'rest' && (
                                <Typography level='body-sm' color='textSecondary'>
                                  {`Implemented as ${func.method?.toUpperCase()} ${func.url?.split('&').join('&\n').replace('?', '?\n')}`}
                                </Typography>
                              )}
                            </CardContent>
                          </Card>
                        }
                      >
                        <Typography level='body-md'>
                          <b>{func.name}</b> (
                          {func.parameters?.map(
                            (param, ind) =>
                              `${ind !== 0 ? ', ' : ''}${param.name}`
                          )}
                          )
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Typography level='body-sm' color='textSecondary'>
                      {func.description}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={2}>
                    <Box
                      sx={{ display: 'flex', m: 0, justifyContent: 'flex-end' }}
                    >
                      <Button
                        variant='solid'
                        size='sm'
                        disabled={editIndex !== null}
                        startDecorator={<EditIcon />}
                        onClick={() => handleEditFunction(index)}
                        sx={{ ml: 'auto', m: 1 }}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Grid>
                  <Grid xs={12}>
                    <Divider orientation='horizontal' />
                  </Grid>
                </>
              )
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={1}>
            {apiKeys.map((value, index) => (
              <Box key={index} sx={{ p: 1 }}>
                <EditKey {...{ value, index }} onChange={handleKeyChange} />
              </Box>
            ))}
          </TabPanel>
        </Tabs>
      </Sheet>

      {editIndex === null && (
        <MenuFab
          options={[
            {
              name: 'Add',
              onClick: handleAddFunction,
              icon: () => <AddIcon />
            },
            {
              name: 'Import',
              onClick: () => {
                setOasFromFile(false);
                setOasOpen(true);
              },
              icon: () => <SiOpenapiinitiative />
            },
            {
              name: 'Import File',
              onClick: () => {
                setOasFromFile(true);
                setOasOpen(true);
              },
              icon: () => <UploadIcon />
            },
            {
              name: 'Add Key',
              onClick: handleAddKey,
              icon: () => <KeyIcon />
            }
          ]}
        >
          <AddIcon />
        </MenuFab>
      )}
      <OASImport
        open={oasOpen}
        fromFile={oasFromFile}
        setOpen={setOasOpen}
        {...{ functions, setFunctions, apiKeys, setApiKeys }}
      />
      <EditPop
        open={editIndex !== null}
        func={functions[editIndex]}
        index={editIndex}
        setIndex={setEditIndex}
        {...{ functions, setFunctions, setConsistent, apiKeys }}
      />
    </>
  );
};

export default function FunctionCalls({
  functions,
  setFunctions,
  apiKeys,
  setApiKeys,
  disabled,
  sx
}) {
  let [consistent, setConsistent] = useState(true);

  return (
    <FloatingDrawer
      title='Function Calls'
      startDecorator={<ConstructionIcon />}
      buttonText='Functions'
      variant='outlined'
      color='neutral'
      sx={sx}
      disabled={disabled}
      canClose={consistent}
    >
      <FunctionEditor
        {...{ functions, setFunctions, apiKeys, setApiKeys, consistent, setConsistent }}
      />
    </FloatingDrawer>
  );
}
